@import "./fonts/fonts.css";

:root {
    --light: 'Light';
    --regular: 'Regular';
    --medium: 'Medium';
    --semibold: 'SemiBold';
    --bold: 'Bold';
    --italic: 'Italic';
    --light-italic: 'Light Italic';
    --regular-italic: 'Regular Italic';
    --medium-italic: 'Medium Italic';
    --semibold-italic: 'SemiBold Italic';
    --bold-italic: 'Bold Italic';
}

body, * {
    padding: 0;
    -webkit-tap-highlight-color: transparent;
    margin: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    font-family: var(--regular), sans-serif;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
html, body {
    overscroll-behavior-x: none;
}

body.hidden {
    overflow: hidden;
}

#chcepe-bottom-sheet-set-amount > div {
    z-index: 1000;
}

#chcepe-bottom-sheet-detail > div {
    z-index: 1000;
}

div[type=straight] {
    display: none !important;
}

#sheet-content {
    position: absolute !important;
    top: 0 !important;
    bottom: 0 !important;
    right: 0;
    border-radius: 0px !important;
}

#sheet-content div:first-child {
    max-height: 100vh !important;
}
