@font-face {
    font-family: 'Bold';
    src: local('Euclid Circular A Bold'), local('EuclidCircularA-Bold'),
    url('EuclidCircularA-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Light';
    src: local('Euclid Circular A Light'), local('EuclidCircularA-Light'),
    url('EuclidCircularA-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Bold Italic';
    src: local('Euclid Circular A Bold Italic'), local('EuclidCircularA-BoldItalic'),
    url('EuclidCircularA-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Light Italic';
    src: local('Euclid Circular A Light Italic'), local('EuclidCircularA-LightItalic'),
    url('EuclidCircularA-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Regular';
    src: local('Euclid Circular A Regular'), local('EuclidCircularA-Regular'),
    url('EuclidCircularA-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SemiBold';
    src: local('Euclid Circular A SemiBold'), local('EuclidCircularA-SemiBold'),
    url('EuclidCircularA-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Medium';
    src: local('Euclid Circular A Medium'), local('EuclidCircularA-Medium'),
    url('EuclidCircularA-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Italic';
    src: local('Euclid Circular A Italic'), local('EuclidCircularA-Italic'),
    url('EuclidCircularA-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'SemiBold Italic';
    src: local('Euclid Circular A SemiBold Italic'), local('EuclidCircularA-SemiBoldItalic'),
    url('EuclidCircularA-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Medium Italic';
    src: local('Euclid Circular A Medium Italic'), local('EuclidCircularA-MediumItalic'),
    url('EuclidCircularA-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

